/* eslint-disable react/prop-types */
import { lazy, useState, useEffect, Suspense, useReducer, useRef } from "react";

import { useDashboard } from "@/pages/Dashboard/useDashboard";

import { useDocumentTitle } from "@uidotdev/usehooks";

import { Loader } from "../../../../components";

import {
  onSnapshot,
  orderBy,
  limit,
  where,
  query,
  collection,
  getDocs,
  or,
  and,
} from "firebase/firestore";

const HARD_LIMIT = 5;

import { db } from "../../../../util/firebase";
import Repeater from "../Repeater/Repeater";

const Create = lazy(() => import("@/pages/Create"));
const Books = lazy(() => import("@/pages/Books"));
const Verify = lazy(() => import("@/pages/Verify"));
const List = lazy(() => import("@/pages/List"));
const Account = lazy(() => import("@/pages/Account"));

const BooksProvider = lazy(() => import("../../../Books/useBooks"));

function wishes() {
  let hour = new Date().getHours();

  // define the greeting based on the hour
  let greeting;
  if (hour < 12) {
    greeting = "Good Morning";
  } else if (hour < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  // return the greeting
  return greeting;
}

const reducer = (state, action) => {
  switch (action.type) {
    case "NEW_BOOK":
      return [
        ...state,
        {
          ...(action?.payload ?? {}),
        },
      ];

    case "REMOVE_BOOK":
      return state.filter((book) => book.id !== action.payload.id);

    case "UPDATE_BOOK":
      // console.log(state);

      state = state.map((book) => {
        if (book.id === action.payload.id) {
          return action?.payload;
        } else {
          return book;
        }
      });

      // group state;

      // first the favorites, completed, in progress

      
      let new_state = [...state.filter( (item) => item.favorite === true).sort((a, b) => {
        // console.log(a.createdAt.seconds, b.createdAt.seconds);
        return a.createdAt.seconds < b.createdAt.seconds ? 1 : -1;
      }), ...state.filter( (item) => item.favorite !== true && item.status === 2).sort((a, b) => {
        // console.log(a.createdAt.seconds, b.createdAt.seconds);
        return a.createdAt.seconds < b.createdAt.seconds ? 1 : -1;
      }), ...state.filter((item) => item.status === 1).sort((a, b) => {
        // console.log(a.createdAt.seconds, b.createdAt.seconds);
        return a.createdAt.seconds < b.createdAt.seconds ? 1 : -1;
      })];

      // console.log(new_state[0].createdAt.seconds);
      

      return new_state;

    case "default":
      return state;
  }
};

export function UI() {
  let {
    location: { currentPath, navigate },
    currentUser: { user, details },
    // eslint-disable-next-line no-unused-vars
    payload: { total = 0, completed = [], progress = [], favorite = [] },
  } = useDashboard();

  const [books, dispatch] = useReducer(reducer, []);

  const [loader, setLoader] = useState(true);

  const [title, setTitle] = useState("bookaible.ai");

  // const prevFav = usePrevious(favorite);

  useDocumentTitle(title);

  useEffect(() => {
    if (currentPath === "/") {
      setTitle("Your Dashboard");
    } else if (currentPath === "/create") {
      setTitle("Create a Book");
    } else if (currentPath === "/in-progress") {
      setTitle("In-Progress Books");
    } else if (currentPath === "/completed") {
      setTitle("Completed Books");
    } else if (currentPath === "/notifications") {
      setTitle("Your Notifications");
    } else {
      setTitle("bookaible.ai");
    }
  }, [currentPath]);

  const divider = import.meta.PROD ? 1 : 1;

  total = total / divider;
  const statProgress = progress.length / divider;
  const statCompleted = completed.length / divider;

  useEffect(() => {
    if (!db || !user) return;

    return onSnapshot(
      query(
        collection(db, "books"),
        where("uid", "==", user.uid),
        where("trash", "==", false),
        orderBy("createdAt", "desc"),
      ),
      (snapshot) => {
        setLoader(false);

        let arr = snapshot.docChanges();

        let favorites = arr.filter(
          (change) =>
            change.doc.data({
              serverTimestamps: "estimate",
            })["favorite"] == true,
        );

        let completed = arr.filter((change) => {
          let data = change.doc.data({
            serverTimestamps: "estimate",
          });

          return data["favorite"] == false && data["status"] == 2;
        });

        let inprogress = arr.filter((change) => {
          let data = change.doc.data({
            serverTimestamps: "estimate",
          });

          return data["status"] == 1;
        });

        favorites = favorites.slice(0, HARD_LIMIT);

        for (let favorite of favorites) {
          // let type = favorite.type;
          let type;
          if (favorite.type === "added") {
            type = "NEW_BOOK";
          } else if (favorite.type === "modified") {
            type = "UPDATE_BOOK";
          } else if (favorite.type === "removed") {
            type = "REMOVE_BOOK";
          } else {
            return;
          }

          let payload = favorite.doc.data({
            serverTimestamps: "estimate",
          });

          let id = favorite.doc.id;

          if (payload) {
            dispatch({ type, payload: { id, ...payload } });
          }
        }

        if (favorites.length >= HARD_LIMIT) {
          return;
        }

        completed = completed.slice(0, (HARD_LIMIT - favorites.length));

        for (let complete of completed) {
          // let type = favorite.type;
          let type;
          if (complete.type === "added") {
            type = "NEW_BOOK";
          } else if (complete.type === "modified") {
            type = "UPDATE_BOOK";
          } else if (complete.type === "removed") {
            type = "REMOVE_BOOK";
          } else {
            return;
          }

          let payload = complete.doc.data({
            serverTimestamps: "estimate",
          });

          let id = complete.doc.id;

          if (payload) {
            dispatch({ type, payload: { id, ...payload } });
          }
        }

        let repo = [...favorites, ...completed]

        if (repo.length >= HARD_LIMIT) {
          return;
        }


        inprogress = inprogress.slice(0, (HARD_LIMIT - repo.length));


        for(let ip of inprogress) {
          let type;
          if (ip.type === "added") {
            type = "NEW_BOOK";
          } else if (ip.type === "modified") {
            type = "UPDATE_BOOK";
          } else if (ip.type === "removed") {
            type = "REMOVE_BOOK";
          } else {
            return;
          }

          let payload = ip.doc.data({
            serverTimestamps: "estimate",
          });

          let id = ip.doc.id;

          if (payload) {
            dispatch({ type, payload: { id, ...payload } });
          }
        }

        // console.log(favorites, completed, inprogress);

        // Divide into 3 parts;

        // snapshot.docChanges().forEach((change) => {
        //   let type;
        //   if (change.type === "added") {
        //     type = "NEW_BOOK";
        //   } else if (change.type === "modified") {
        //     type = "UPDATE_BOOK";
        //   } else if (change.type === "removed") {
        //     type = "REMOVE_BOOK";
        //   } else {
        //     return;
        //   }

        //   let payload = change.doc.data({
        //     serverTimestamps: "estimate",
        //   });

        //   let id = change.doc.id;

        //   if (payload) {
        //     dispatch({ type, payload: { id, ...payload } });
        //   }
        // });
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, user]);

  const currentToast = useRef(null);

  if (currentPath === "/") {
    const displayName =
      details &&
      typeof details?.displayName === "string" &&
      details?.displayName.indexOf("@") === -1
        ? details.displayName.split(" ")[0]
        : user.email.split("@")[0];
    return (
      <div className="mr-8 mt-2.5 flex w-full flex-col gap-6">
        <div
          className={`relative mt-[10px] flex max-h-[50vh] min-h-[35%] w-full flex-col justify-around rounded-[2rem] bg-gradient-70 from-theme to-light-theme px-6`}
        >
          <h3 className="ml-4 mt-2 font-dm text-[2.4rem] text-white">
            <span className="font-bold">{wishes()}</span>, {displayName}
          </h3>
          <>
            <div className="flex h-[50%] w-[55%] flex-row justify-evenly gap-4 rounded-[1.6rem] bg-light-white">
              <Stat
                stattype={"Books In-Progress"}
                statcount={statProgress}
                key="bip"
              />
              <Stat
                stattype={"Completed Books"}
                statcount={statCompleted}
                key="cb"
              />
              <Stat stattype={"Total Books"} statcount={total} key="tb" />
            </div>
            <img
              src="https://cdn.bookaible.ai/dashboard/steps.svg"
              className="absolute right-0 mt-5 scale-[0.8]"
            />
          </>
        </div>
        {loader && (
          <div className="flex h-full flex-row items-center justify-center">
            <Loading />
          </div>
        )}
        {!loader && (
          <div className="mb-4 flex h-full flex-col overflow-hidden rounded-[2rem] bg-white">
            <div className="mx-5 mt-6 flex w-full flex-row items-center rounded-t-[2rem] bg-white px-5 pb-2">
              <h6 className="font-dm font-bold">Your Recent Books</h6>
            </div>
            <div className="mt-4 flex w-full flex-grow flex-col items-center gap-4 overflow-y-auto rounded-b-[2rem] bg-white">
              {books.map((book) => (
                <Repeater book={book} key={book?.id} />
              ))}
              {books.length === 0 && (
                <div className="my-auto flex flex-col items-center justify-center gap-5">
                  <h3 className="font-dm">
                    &ldquo;It&apos;s time to run your imagination wild with
                    Bookaible.&rdquo;
                  </h3>
                  <h5 className="font-dm">
                    Here is your gateway to create amazing books.
                  </h5>
                  <button
                    className="primary-btn"
                    onClick={() =>
                      navigate(details.verified ? "/create" : "/verify")
                    }
                  >
                    Get Started!
                  </button>
                </div>
              )}
            </div>
            {/* <div className="flex flex-row items-center bg-white w-full rounded-b-[2rem] p-3"></div> */}
          </div>
        )}
      </div>
    );
  }

  if (currentPath === "/verify") {
    return (
      <Suspense fallback={<Loader />}>
        <Verify />
      </Suspense>
    );
  }

  if (currentPath === "/my-account") {
    return (
      <Suspense fallback={<Loader />}>
        <Account />
      </Suspense>
    );
  }

  if (currentPath === "/in-progress" || currentPath === "/completed") {
    return (
      <Suspense fallback={<Loader />}>
        <List status={currentPath === "/in-progress" ? 1 : 2} />
      </Suspense>
    );
  }

  if (currentPath === "/create") {
    return (
      <Suspense fallback={<Loader />}>
        <Create />
      </Suspense>
    );
  }

  if (currentPath.indexOf("admin") !== -1) {
    return (
      <Suspense fallback={<Loader />}>
        <p>Comming Soon</p>
      </Suspense>
    );
  }

  if (currentPath.indexOf("books") !== -1) {
    return (
      <Suspense fallback={<Loader />}>
        <BooksProvider>
          <Books />
        </BooksProvider>
      </Suspense>
    );
  }
}

export function MobileDetails() {
  const divider = import.meta.PROD ? 1 : 1;

  // eslint-disable-next-line no-unused-vars
  let {
    currentUser: { user, details },
    // eslint-disable-next-line no-unused-vars
    payload: { total = 0, completed = [], progress = [], favorite = [] },
    location: { currentPath },
  } = useDashboard();

  total = total / divider;
  const statProgress = progress.length / divider;
  const statCompleted = completed.length / divider;

  const displayName =
    details &&
    typeof details?.displayName === "string" &&
    details?.displayName.indexOf("@") === -1
      ? details.displayName.split(" ")[0]
      : user.email.split("@")[0];

  if (currentPath !== "/") {
    return <></>;
  }

  return (
    <div className={`flex flex-col gap-10 px-5 py-0 pb-6 pt-2 sm:px-7 sm:py-4`}>
      <h3 className="mt-2 font-dm text-[2.4rem] text-white">
        <span className="font-bold">{wishes()}</span>, {displayName}
      </h3>
      <div
        className={`hidden h-[80%] w-full flex-row justify-evenly gap-4 rounded-[1.6rem] bg-light-white pb-4 sm:flex`}
      >
        <Stat
          stattype={"Books In-Progress"}
          statcount={statProgress}
          key="bip"
        />
        <Stat stattype={"Completed Books"} statcount={statCompleted} key="cb" />
        <Stat stattype={"Total Books"} statcount={total} key="tb" />
      </div>
    </div>
  );
}

export function MobileUI() {
  const {
    location: { currentPath, navigate },
    // eslint-disable-next-line no-unused-vars
    currentUser: { user, details },
  } = useDashboard();

  // eslint-disable-next-line no-unused-vars
  const [books, dispatch] = useReducer(reducer, []);

  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(true);

  const [title, setTitle] = useState("bookaible.ai");

  // const prevFav = usePrevious(favorite);

  useDocumentTitle(title);

  useEffect(() => {
    if (currentPath === "/") {
      setTitle("Your Dashboard");
    } else if (currentPath === "/create") {
      setTitle("Create a Book");
    } else if (currentPath === "/in-progress") {
      setTitle("In-Progress Books");
    } else if (currentPath === "/completed") {
      setTitle("Completed Books");
    } else if (currentPath === "/notifications") {
      setTitle("Your Notifications");
    } else {
      setTitle("bookaible.ai");
    }
  }, [currentPath]);

  useEffect(() => {
    if (!db || !user) return;

    return onSnapshot(
      query(
        collection(db, "books"),
        where("uid", "==", user.uid),
        where("trash", "==", false),
        orderBy("createdAt", "desc"),
      ),
      (snapshot) => {
        setLoader(false);
        let arr = snapshot.docChanges();

        let favorites = arr.filter(
          (change) =>
            change.doc.data({
              serverTimestamps: "estimate",
            })["favorite"] == true,
        );

        let completed = arr.filter((change) => {
          let data = change.doc.data({
            serverTimestamps: "estimate",
          });

          return data["favorite"] == false && data["status"] == 2;
        });

        let inprogress = arr.filter((change) => {
          let data = change.doc.data({
            serverTimestamps: "estimate",
          });

          return data["status"] == 1;
        });

        favorites = favorites.slice(0, HARD_LIMIT);

        for (let favorite of favorites) {
          // let type = favorite.type;
          let type;
          if (favorite.type === "added") {
            type = "NEW_BOOK";
          } else if (favorite.type === "modified") {
            type = "UPDATE_BOOK";
          } else if (favorite.type === "removed") {
            type = "REMOVE_BOOK";
          } else {
            return;
          }

          let payload = favorite.doc.data({
            serverTimestamps: "estimate",
          });

          let id = favorite.doc.id;

          if (payload) {
            dispatch({ type, payload: { id, ...payload } });
          }
        }

        if (favorites.length >= HARD_LIMIT) {
          return;
        }

        completed = completed.slice(0, (HARD_LIMIT - favorites.length));

        for (let complete of completed) {
          // let type = favorite.type;
          let type;
          if (complete.type === "added") {
            type = "NEW_BOOK";
          } else if (complete.type === "modified") {
            type = "UPDATE_BOOK";
          } else if (complete.type === "removed") {
            type = "REMOVE_BOOK";
          } else {
            return;
          }

          let payload = complete.doc.data({
            serverTimestamps: "estimate",
          });

          let id = complete.doc.id;

          if (payload) {
            dispatch({ type, payload: { id, ...payload } });
          }
        }

        let repo = [...favorites, ...completed]

        if (repo.length >= HARD_LIMIT) {
          return;
        }


        inprogress = inprogress.slice(0, (HARD_LIMIT - repo.length));


        for(let ip of inprogress) {
          let type;
          if (ip.type === "added") {
            type = "NEW_BOOK";
          } else if (ip.type === "modified") {
            type = "UPDATE_BOOK";
          } else if (ip.type === "removed") {
            type = "REMOVE_BOOK";
          } else {
            return;
          }

          let payload = ip.doc.data({
            serverTimestamps: "estimate",
          });

          let id = ip.doc.id;

          if (payload) {
            dispatch({ type, payload: { id, ...payload } });
          }
        }
        
        // console.log(favorites, completed, inprogress);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, user]);

  const currentToast = useRef(null);

  if (currentPath === "/") {
    return (
      <>
        {!loader && (
          <div className="m-6 flex flex-grow flex-col items-center justify-center overflow-hidden rounded-[2rem] bg-white">
            <>
              <div className="flex w-full flex-row items-center rounded-t-[2rem] border-b-[1px] border-b-theme bg-white pb-3 pl-6 pt-4">
                <h6 className="font-dm font-bold">Your Recent Books</h6>
              </div>
              <div className="no-scrollbar flex h-[100%] w-full flex-col items-center gap-4 overflow-x-hidden overflow-y-visible rounded-b-[2rem] bg-white">
                {books.map((book) => (
                  <Repeater book={book} key={book?.id} />
                ))}
                {books.length === 0 && (
                  <div className="my-auto flex flex-col items-center justify-center gap-5 text-center">
                    <h4 className="font-dm">
                      &ldquo;It&apos;s time to run your imagination wild with
                      Bookaible.&rdquo;
                    </h4>
                    <h6 className="font-dm">
                      Here is your gateway to create amazing books.
                    </h6>
                    <button
                      className="primary-btn"
                      onClick={() =>
                        navigate(details.verified ? "/create" : "/verify")
                      }
                    >
                      Get Started!
                    </button>
                  </div>
                )}
              </div>
              <div className="flex h-[5px] w-full flex-row"></div>
            </>
          </div>
        )}
      </>
    );
  }

  if (currentPath === "/my-account") {
    return (
      <Suspense fallback={<Loader />}>
        <Account />
      </Suspense>
    );
  }

  if (currentPath === "/in-progress" || currentPath === "/completed") {
    return (
      <Suspense fallback={<Loader />}>
        <List status={currentPath === "/in-progress" ? 1 : 2} />
      </Suspense>
    );
  }

  if (currentPath === "/create") {
    return (
      <Suspense fallback={<Loader />}>
        <Create />
      </Suspense>
    );
  }

  if (currentPath === "/verify") {
    return (
      <Suspense fallback={<Loader />}>
        <Verify />
      </Suspense>
    );
  }

  if (currentPath.indexOf("admin") !== -1) {
    return (
      <Suspense fallback={<Loader />}>
        <p>Coming Soon</p>
      </Suspense>
    );
  }

  if (currentPath.indexOf("books") !== -1) {
    return (
      <Suspense fallback={<Loader />}>
        <BooksProvider>
          <Books />
        </BooksProvider>
      </Suspense>
    );
  }
}

function Stat({ stattype, statcount }) {
  statcount = statcount.toString();
  return (
    <div className={`flex w-[33%] flex-col justify-around p-4 text-black`}>
      <div className="flex w-full flex-row justify-center">
        <p className="font-dm font-bold text-primary/70">{stattype}</p>
      </div>
      <div className="flex h-[50%] w-full flex-row justify-center">
        <h1 className="font-dm font-bold">{statcount}</h1>
      </div>
    </div>
  );
}

function Loading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
      stroke="#1F2E43"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
}
