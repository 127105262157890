import { getApp, getApps, initializeApp} from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getPerformance } from 'firebase/performance';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken as getMessagingToken } from 'firebase/messaging'

export const firebaseConfig = {
    apiKey: import.meta.env["PUBLIC_FIREBASE_API"],
    authDomain: import.meta.env["PUBLIC_FIREBASE_AUTH_DOMAIN"],
    projectId: import.meta.env["PUBLIC_PROJECT_ID"],
    storageBucket: `${import.meta.env["PUBLIC_PROJECT_ID"]}.appspot.com`,
    messagingSenderId: import.meta.env['PUBLIC_PROJECT_FCM_VAPID_ID'],
    appId: import.meta.env["PUBLIC_PROJECT_APP_ID"],
    measurementId: import.meta.env["PUBLIC_PROJECT_MEASUREMENT_ID"]
};

const SITE_KEY = import.meta.env['PUBLIC_RECAPTCHA_SITE_KEY'];

export const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
export const analytics = getAnalytics(app);
export const perfomance = getPerformance(app);
export const db = getFirestore(app);
export const messaging = getMessaging(app);
export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(SITE_KEY),
    isTokenAutoRefreshEnabled: true
});

export const storage = getStorage(app);

export const auth = getAuth(app);

export const getToken = async () => {
    const { getLimitedUseToken } = await import('firebase/app-check');
    return await getLimitedUseToken(appCheck);
}

// export async function getConnected() {
//     if(import.meta.PROD) return;
//     const { connectAuthEmulator } = await import("")
//     connectAuthEmulator(auth, "http://127.0.0.1:8000", {
//         disableWarnings: true
//     });

//     connectFirestoreEmulator(db, "127.0.0.1", 8001);

//     connectStorageEmulator(storage, "127.0.0.1", 8005)
// }

export function getAuthError(code) {
    let formerror
    switch(code) {
        case 'auth/user-not-found':
            formerror = `Seems like you do not have an account! Continue to sign up.`;
        break;
        case 'auth/account-exists-with-different-credential':
            formerror = `An account already exists with the same email address.`;
            break;
        case 'auth/email-already-in-use':
            formerror = "Seems like you already have an account! Contine to Sign In";
            break;
        case 'auth/invalid-email':
        case 'auth/wrong-password':
            formerror = "Invalid Email or Password.";
            break;
        case 'auth/user-disabled':
            formerror = "Your account has been disabled. Please contact us.";
            break;
        case 'auth/weak-password':
            formerror = "This is a very weak password. Try again";
            break;
        case 'auth/too-many-requests':
            formerror = "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
            break;
        case 'auth/user-cancelled':
        case 'auth/popup-closed-by-user':
            formerror = "The authencation was closed or cancelled. Please try again."
            break;
        case 'auth/network-request-failed':
            formerror = "We could not process your request. Please check your network and try again."
            break;
        case 'auth/timeout':
            formerror = "The authencation request timeout out as the required action was not taken within the required interval.";
            break;
        default:
            formerror = "There was an error while authenticating. Please contact support@bookaible.ai for more help.";
            break;
    }

    return formerror;
}


export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const isValidEmail = (email) => email.indexOf("@") !== -1 && email.indexOf("@") === email.lastIndexOf("@") && EMAIL_REGEX.test(email);

export const VAPID_KEY = "BMdEzBbAVwpFFkSM6EQ4UiHq9Haze8BfU_MIXhybQLrpHWxN9gFxz_uaNMcLicjLUvgkJzak2Opq8UsD17Zq2zk"


export const getBookFromArr = (id, books = []) => {
    let bookArr = []
    bookArr = books.filter(item => item.id === id);
    
    if(bookArr.length < 1) return null;

    return bookArr[0]
}

export async function saveMessagingDeviceToken(uid = null, save = false, count = 100) {
    if(count === 0) {
        return false;
    }
    try {
        const fcmToken = await getMessagingToken(messaging, {
            vapidKey: VAPID_KEY
        })
    
        if(fcmToken) {
            // console.log('GOT FCM device token: ', fcmToken, " for uid: ", uid);
        }

        if(save && typeof uid === 'string' && uid.length > 0) {
            // Lets save it

            const {doc, updateDoc, arrayUnion } = await import('firebase/firestore');
            await updateDoc(doc(db, "users", uid), {
                fcmToken: arrayUnion(fcmToken)
            })
        }

        return fcmToken;
    } catch (err) {

        console.error( "Got an error, may be the sw failed: ", err.message );
        
        if(err.message.indexOf("PushManager") !== -1) {
            // call again
            return await saveMessagingDeviceToken(uid, save, (count-1))
        }
    }
}


export async function requestPermission() {
    // console.log("Request Permission");
    const permission = await Notification.requestPermission();
    
    return permission;
}