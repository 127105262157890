import DashboardProvider, { useDashboard } from "./useDashboard";
import { ToastContainer } from "react-toastify";
import { Suspense } from "react";
import { Loader } from "../../components";
import { useLocalStorage } from "@uidotdev/usehooks";
import useDevice from "../../hooks/useDevice";
import { SideBar, MobileSideBar, MobileNavBar } from "./components/SideBar";
import { UI, MobileUI, MobileDetails } from "./components/UI";
import Payment from "../../components/Payment";
import OnHold from "../../components/OnHold";

export default function Dashboard() {
  const device = useDevice();

  if (device === "desktop") {
    return (
      <DashboardProvider>
        <div className={`flex h-[100vh] w-full flex-row gap-5 bg-page`}>
          <Suspense fallback={<Loader />}>
            <VerifyAlert device={device}/>
            <SideBar />
            <PWAPromote />
            <UI />
            <Payment />
            <OnHold />
          </Suspense>
        </div>
        <ToastContainer stacked={true} />
      </DashboardProvider>
    );
  }

  return (
    <DashboardProvider>
      <Suspense fallback={<Loader />}>
        <div className="fixed top-0 flex flex-col w-full z-50">
          <VerifyAlert device={device}/>
          <PWAPromote />
        </div>
        <div className="flex h-[100vh] max-h-full w-full max-w-full flex-col overflow-y-hidden bg-page">
          <div className="flex flex-col justify-between bg-gradient-70 from-theme from-[0%] to-light-theme to-[159.83%]">
            <MobileSideBar />
            <MobileDetails />
          </div>
          <MobileUI />
          <MobileNavBar />
        </div>
        <Payment />
      </Suspense>
      <ToastContainer stacked={true} />
    </DashboardProvider>
  );
}

function PWAPromote() {

	const { pwa } = useDashboard()
	const device = useDevice();

	

	if(pwa.show && pwa.promote) {
	
		if(device === "phone") {
			return (
				<div className="flex top-0 h-fit w-full flex-col bg-bk-focus p-4 relative z-20">
					<p className="w-[80%] font-dm font-bold text-white">
						Enhance your book-writing experience! Install our free app now.
					</p>
					<div className="flex flex-row items-center justify-end gap-5">
						<span role="button" className="font-dm text-[2.2rem] text-page -top-2 right-3 cursor-pointer absolute" onClick={() => pwa.dismiss()}>&times;</span>
						<button className="primary-btn !h-10" onClick={() => pwa.installApp()}>Install</button>
					</div>
				</div>
			);
		} else {
			return (
				<div className="fixed bg-bk-focus/95 flex flex-col right-[10px] bottom-[15px] p-4 rounded-lg w-[25%] h-fit gap-4 z-20">
					<p className="w-fit font-dm font-bold text-white">
						Enhance your book-writing experience! Install our free app now.
					</p>
					<div className="flex flex-row items-center justify-end gap-8">
						<span role="button" className="font-dm text-[2.2rem] text-page -top-2 right-3 cursor-pointer absolute" onClick={() => pwa.dismiss()}>&times;</span>
						<button className="primary-btn !h-10" onClick={() => pwa.installApp()}>Install</button>
					</div>
				</div>
			)
		}
	}

	return (
		<></>
	)

}

function BetaAlert() {
  const {
    currentUser: { details },
  } = useDashboard();

  const [beta, setBeta] = useLocalStorage("beta-banner", "no");

  const dismiss = () => {
    setBeta("yes");
  };

  if (!details.verified || beta === "yes") {
    return <></>;
  }
  return (
    <div className="fixed top-0 z-10 flex h-fit w-full items-center justify-center gap-2 bg-yellow-500 p-3 text-black">
      <p className="font-dm !text-[12px] font-bold md:!text-[15px]">
        Warning! This application is not fully released. Your valuable feedback
        is always welcome. Thanks!
      </p>
      <a
        className="primary-btn flex font-dm !text-[12px] font-bold md:!text-[15px]"
        role="button"
        onClick={dismiss}
      >
        <span className="">Dismiss</span>
      </a>
    </div>
  );
}

function VerifyAlert({device}) {
  const {
    currentUser: { details },
  } = useDashboard();

  if (details.verified) {
    return <></>;
  }


  
  return (
    <div className={`${device === "desktop" ? "fixed top-0 z-10" : ""} flex h-[1.7rem] w-full items-center justify-center bg-yellow-500 font-dm text-[15px] font-bold text-black`}>
      <p>
        Your email address is not verified.{" "}
        <a className="underline" href="/verify">
          Get Started!
        </a>
      </p>
    </div>
  );
}
