import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './css/index.css'
import './css/button.css'
const location = window.location;

const regex = /^(https?:\/\/)?([\w-]+\.)+(web\.app|firebaseapp\.com)$/

const isDefault = regex.test(location.hostname);

!isDefault ? (ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)) : window.location.replace(import.meta.env.PUBLIC_DEFAULT_DOMAIN)