import { useRef } from "react";
import { useDashboard } from "../../pages/Dashboard/useDashboard";

export default function OnHold() {

  const dialog = useRef(null);

  const {
    currentUser: { details },
  } = useDashboard();

  const { service: { status, message = "" } } = details;

  return (
    <dialog
      className="fixed bottom-0 top-0 z-50 block h-full max-h-full w-full max-w-full scale-0 overflow-hidden outline-0 transition-all duration-200 ease-linear open:scale-100 open:bg-black/40"
      ref={dialog}
      open={(status === "on_hold")}
    >
      <div
        className={`relative top-[50%] m-0 flex max-h-full max-w-full -translate-y-[50%] flex-col rounded-md md:top-[50%] md:m-[1.75rem] md:mx-auto md:max-h-[calc(100%-3.5rem)] md:max-w-[40%] md:-translate-y-[50%]`}
      >
        <div
          className={`flex w-full flex-row items-center justify-between rounded-t-md border-b-[2px] border-b-theme bg-white p-2 py-4`}
        >
          <h4 className="pl-4 font-dm">{status === "paused" ? "Crtitical Account Error" : (status === "on_hold" ? "Just a moment!" : "")}</h4>
        </div>
        <div className="flex h-full min-h-fit w-full flex-col justify-center items-center gap-3 rounded-b-md bg-white px-4 py-4">
            <Loading />
            <p className="text-primary font-dm font-semibold text-[1.2rem] text-center">{message}</p>
        </div>
      </div>
    </dialog>
  );
}

function Loading({ className = "" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
      className={className}
      stroke="#1F2E43"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="currentColor"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
}
