import { useMediaQuery } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";

export default function useDevice() {
  
    const isPhone = useMediaQuery("only screen and (max-width : 767px) and (orientation: portrait)");
    const isTablet = useMediaQuery(
        "only screen and (min-width : 768px) and (max-width : 991px) and (orientation: portrait)"
    );
    const isDesktop = useMediaQuery(
        "only screen and (min-width : 992px)"
    );

    const [ device, setDevice ] = useState(null);

    useEffect(() => {

        let deviceType = ""

        if(isPhone) {
            // setDevice("phone")

            deviceType = "phone";
        }

        if(isTablet) {
            // setDevice("tablet")
            deviceType = "tablet";
        }

        if(isDesktop) {
            // setDevice("desktop")
            deviceType = "desktop";
        }
        setDevice(deviceType);

        return () => false;

    }, [isPhone, isTablet, isDesktop]);


    return device;

}
