import { useSearchParams } from "react-router-dom";


export default function ThankYou() {

  const [ searchParams ] = useSearchParams();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-4 font-dm">Thank You!</h1>
      <p className="text-lg mb-8">
        We have recieved your payment and the status will be updated to you shortly. Thank you for choosing Bookaible.
      </p>
      <a className="primary-btn" href="/" role="button">
        Back to Dashboard
      </a>
    </div>
  );
}
